import { React, useContext, useState, useEffect } from "react";
import * as moment from "moment";
import "react-toastify/dist/ReactToastify.css";

import Tables from "components/table/table";
import reportAPI from "apis/panel/reports/reports";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Context from "context/context";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "100%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  width: "100vw",
  // p: 4,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflowY: "scroll",
  // overflowX: "scroll"
};

const VraMonthlyScheduleForecastCalendar = ({ setDisableDropdown }) => {
  const { dispatch } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [radScheduleData, setRadScheduleData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [initial, setInitial] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const {getVraScheduleForecastCalendar, getVraScheduleForcastCalendarReportView} =
    reportAPI();

  const handleClose = () => {
    setOpen(false);
  };
  const initialStartDate = moment().startOf("month").toDate();
  const initialEndDate = new Date();

  const [exportObj, setExportObj] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  useEffect(() => {
    if (!initial) {
      handleView();
    }
  }, [offset, searchValue]);

  const handleView = async () => {
    if (!exportObj?.startDate || !exportObj?.endDate) {
      alert("Start Date and End Date must not be empty.");
      return;
    }

    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let value = {
        offset: (offset - 1) * 20,
        limit: 20,
      };
      if (searchValue) {
        value = {
          ...value,
          searchValue,
        };
      }
      const values = {
        ...value,
        startDate: moment(exportObj.startDate).format("YYYY-MM-DD"),
        endDate: moment(exportObj.endDate).format("YYYY-MM-DD"),
      };

      let res = await getVraScheduleForcastCalendarReportView(values);

      setCount(res?.data?.count);
      setRadScheduleData(res?.data?.data);
      dispatch({ type: "SET_LOADING", payload: false });
      setOpen(true);
      setInitial(false);
    } catch (err) {
      console.log({ err });
      dispatch({ type: "SET_LOADING", payload: false });
      setOpen(false);
    }
  };

  const handleExport = async () => {
    let body = {};
    if (!exportObj?.startDate) {
      return;
    }
    if (!exportObj?.endDate) {
      return;
    }
    body = {
      startDate: moment(exportObj?.startDate).format("YYYY-MM-DD"),
      endDate: moment(exportObj?.endDate).format("YYYY-MM-DD"),
    };

    dispatch({ type: "SET_LOADING", payload: true });
    setDisableDropdown(true);
    await getVraScheduleForecastCalendar(body);
    setDisableDropdown(false);
    dispatch({ type: "SET_LOADING", payload: false });
  };

 
  const generateDateHeadersWithSubheaders = (startDate, endDate) => {
    const dateHeaders = [];
    let currentDate = moment(startDate);
  
    while (currentDate <= moment(endDate)) {
      const dateStr = currentDate.format("DD-MMM-YYYY");
      dateHeaders.push(dateStr);
      currentDate = currentDate.clone().add(1, "day");
    }

    return { dateHeaders,  };
  };

  const { dateHeaders} = generateDateHeadersWithSubheaders(exportObj.startDate, exportObj.endDate);

  const heading = [
    "S.No",
    "Employee Id",
    "RP ID",
    "VRA",
    "Shift",
    "Role",
    "Engagement Type",
    "InSchedule",
    "Leave",
    ...dateHeaders.flatMap(date => [date])
  ];
  
  // Add a separate row for subheaders below the main headers
//   const subheading = [
//     "", // Empty cell for S.No
//     "", // Empty cell for Rad Qgenda Name
//     "", // Empty cell for Practice
//     "", // Empty cell for No. of VRA's
//     ...subHeaders,
//   ];
  

  
  // Generate date headers for Tables component
//   const dateHeaders = generateDateHeaders(exportObj.startDate, exportObj.endDate);

  return (
    <div>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={exportObj?.startDate}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>
          <Button
            className="download-btn"
            sx={{ marginRight: "10px" }}
            startIcon={
              <RemoveRedEyeOutlinedIcon
               style={{ fontSize: 30, verticalAlign: "middle", pl: "2px" }}
              />
            }
            onClick={handleView}
          >
            View
          </Button>
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
           >
            Export
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
        <Tables
  title={
    <Box sx={{ pt: 2, pb: 2 }} className="VraShiftsTitle">
      VRA Schedule Forecast Calendar View
    </Box>
  }
  searchBar={true}
  setSearchValue={setSearchValue}
  create={
    <IconButton onClick={handleClose}>
      <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
    </IconButton>
  }
  heading={heading}
//   subheading={subheading}
  data={radScheduleData?.map((e, index) => [
    (offset - 1) * 20 + index + 1, // S.No
    e.employee_code, // Rad Qgenda Name
    e.vra_fullname, // Practice
    e.srf_name, 
    e.primary_shift,
    e.role,
    e.engagement_type,
    e.in_schedule,
    e.leave,// No. of VRA's
  
    ...dateHeaders.flatMap((date) => {
      const shiftForDate = e.shift_obj[moment(date).format("YYYY-MM-DD")]; // Format the date to match the keys in shift_obj
      return shiftForDate
       
    }),
  ])}
  offset={offset}
  setOffset={setOffset}
  count={count}
  placeholder="Search by RP ID"
  limit={20}
/>

        </Box>
      </Modal>
    </div>
  );
};

export default VraMonthlyScheduleForecastCalendar;
