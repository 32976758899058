import {React, useContext,useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import * as moment from "moment";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Context from "context/context";
import reportAPI from 'apis/panel/reports/reports';
import Modal from '@mui/material/Modal';
import Tables from "components/table/table";

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: "100%",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    width: "90vw",
    // p: 4,
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "scroll",
    // overflowX: "scroll"
  };

const VraMonthlySchedule = ({ setDisableDropdown }) => {
    const { dispatch } = useContext(Context);
    const [VraMonthlyScheduleData, setVraMonthlyScheduleData]= useState([]);
    const [open , setOpen] = useState(false)
    const [offset, setOffset] = useState(1);
    const [initial,setInitial]= useState(true)
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const {
        getVraMonthlySchedule, getVraMonthlyScheduleView
      } = reportAPI();

      const initialStartDate = moment().startOf('month').toDate();

      const initialEndDate = new Date();
  
      const [exportObj, setExportObj] = useState({
          startDate: initialStartDate,
          endDate: initialEndDate,
      });

      const handleClose = () => {
        setOpen(false);
      }

 useEffect(()=>{
  if(!initial){
    handleView()
  }
   
 },[offset,searchValue])

      const handleView = async () => {
        if (!exportObj?.startDate || !exportObj?.endDate) {
       
          alert("Start Date and End Date must not be empty.");
          return;
        }
      
        try {
          dispatch({ type: "SET_LOADING", payload: true });
         let value = {
            offset: (offset - 1) * 20,
            limit: 20,
          };
          if (searchValue) {
            value = {
                ...value,
              searchValue,
            };
          }
          const values = {
            ...value,
            startDate: moment(exportObj.startDate).format("YYYY-MM-DD"),
        endDate: moment(exportObj.endDate).format("YYYY-MM-DD"),
          };
 
      
          let res = await getVraMonthlyScheduleView(values);
      
         
          setCount(res?.data?.count)

          setVraMonthlyScheduleData(res?.data?.data)
          setInitial(false)
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(true)
        } catch (err) {
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(false)
        }
      };

      const handleExport = async () => {
        let body = {};
        if (!exportObj?.startDate) {
          return;
        }
        if (!exportObj?.endDate) {
          return;
        }
        body = {
          startDate: moment(exportObj?.startDate).format("YYYY-MM-DD"),
          endDate: moment(exportObj?.endDate).format("YYYY-MM-DD"),
        };
       
        dispatch({ type: "SET_LOADING", payload: true });
        setDisableDropdown(true);
       
        await getVraMonthlySchedule(body);
        setDisableDropdown(false);
        dispatch({ type: "SET_LOADING", payload: false });
      };
    
  return (
    <div>
          <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>


      </Grid>

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>

        <Button
            className="download-btn"
            sx={{marginRight:'10px'}}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ fontSize: 30, verticalAlign: "middle" ,pl:'2px'}}
              />
            }
            onClick={handleView}
          >
            View
          </Button>
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
     
      


          <Tables
        title={
          <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">VRA Monthly Schedule View</Box>
        }
        searchBar={true}
        create={    <IconButton
          onClick={handleClose}
          // sx={{ position: 'absolute', top: 3, right: 3 }}
        >
          <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
        </IconButton>}
        // searchBar={roleCode === "R001"}
        // filter={roleCode === "R001"}
        setSearchValue={setSearchValue}
        // filterValue={filterValue}
        // setFilterValue={setFilterValue}
        // filterValues={[
        //   { label: "Employee Id", value: "" },
        //   { label: "VRA Name", value: "employeeName" },
        // ]}
        heading={[
       
          "S.No",
          "VRA SRF Name",
          "VRA Full Name",
          "RAD Qgenda Full Name",
          "Month",
          "Year",
          "Current month",
          "Shift Date",
          "Shift Start Time",
          "Shift End Time",
          "Shift Type",
          "Total Service Hours",
          "Running Total Hours",
          "Assignment Type",
          "Created On",
          "Updated on"
        ]}
        data={VraMonthlyScheduleData?.map((e, index) => [
         (offset-1)*20 +index+1,
         e.srf_name,
         e.vra_fullname,
         e.rad_qgenda_fullname,
         e.month,
         e.year,
         e.current_month?'true':'false',
         moment(e.shift_date).format("DD-MMM-YYYY"),
         e.shift_start_time,
         e.shift_end_time,
         e.shift_type,
         e.total_service_hours,
         e.running_total_hours,
         e.assignment_type,
         e.created_on,
         e.updated_on
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         placeholder="Search by Name"
         limit={20}
        //  placeholder="Search by ID, VRA Name"
      />
    
        </Box>
      </Modal>
    </div>
  )
}

export default VraMonthlySchedule