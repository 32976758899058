import {React, useContext,useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import * as moment from "moment";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import reportAPI from 'apis/panel/reports/reports';
import Tables from "components/table/table";

import Context from "context/context";

import Modal from '@mui/material/Modal';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import moment from 'moment';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: "100%",
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    width: "100vw",
    // p: 4,
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "scroll",
    // overflowX: "scroll"
  };

const RadScheduleForecast = ({ setDisableDropdown }) => {
    const { dispatch } = useContext(Context);
    const [open , setOpen]= useState(false)
    const [radScheduleData, setRadScheduleData]= useState([]);
    const [offset, setOffset] = useState(1);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [initial,setInitial]= useState(true)
    const {
        getRadScheduleForecast, getRadScheduleForecastView
      } = reportAPI();

      const handleClose = () => {
        setOpen(false);
      }
      const initialStartDate = moment().startOf('month').toDate();
     
      const initialEndDate = new Date();
  
      const [exportObj, setExportObj] = useState({
          startDate: initialStartDate,
          endDate: initialEndDate,
      });

      useEffect(()=>{
        if(!initial){
          handleView()
        }
         
       },[offset,searchValue])

      const handleView = async () => {
        if (!exportObj?.startDate || !exportObj?.endDate) {
          
          alert("Start Date and End Date must not be empty.");
          return;
        }
      
        try {
          dispatch({ type: "SET_LOADING", payload: true });
      
          

        let value = {
            offset: (offset - 1) * 20,
            limit: 20,
          };

          if (searchValue) {
            value = {
                ...value,
              searchValue,
            };
          }
          const values = {
            ...value,
            startDate:  moment(exportObj?.startDate).format("YYYY-MM-DD"),
            endDate:  moment(exportObj?.endDate).format("YYYY-MM-DD"),
          };
          
      
        
      
          let res = await getRadScheduleForecastView(values);
      
        
          setCount(res?.data?.count)

          setRadScheduleData(res?.data?.data)

      
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(true)
          setInitial(false)
        } catch (err) {
          dispatch({ type: "SET_LOADING", payload: false });
          setOpen(false)
        }
      };
      
      const handleExport = async () => {
        let body = {};
        if (!exportObj?.startDate) {
          return;
        }
        if (!exportObj?.endDate) {
          return;
        }
        body = {
          startDate: moment(exportObj?.startDate).format("YYYY-MM-DD"),
          endDate: moment(exportObj?.endDate).format("YYYY-MM-DD"),
        };
       
        dispatch({ type: "SET_LOADING", payload: true });
        setDisableDropdown(true);
       
        await getRadScheduleForecast(body);
        setDisableDropdown(false);
        dispatch({ type: "SET_LOADING", payload: false });
      };
    
  return (
    <div>
          <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
            //   maxDate={exportObj?.endDate}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>  

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
           
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={exportObj?.startDate}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

          </Grid>

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>
        <Button
            className="download-btn"
            sx={{marginRight:'10px'}}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ fontSize: 30, verticalAlign: "middle" ,pl:'2px'}}
              />
            }
            onClick={handleView}
          >
            View
          </Button>

     
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Tables
        title={
          <Box sx={{ pt: 2, pb: 2}} className="VraShiftsTitle">RAD Schedule Forecast View</Box>
        }
        searchBar={true}
        // filter={roleCode === "R001"}
        setSearchValue={setSearchValue}
        create={    <IconButton
          onClick={handleClose}
        >
          <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
        </IconButton>}
        // filterValue={filterValue}
        // setFilterValue={setFilterValue}
        // filterValues={[
        //   { label: "Employee Id", value: "" },
        //   { label: "VRA Name", value: "employeeName" },
        // ]}
        heading={[
          "S.No",
          "First Name",
          "Last Name",
          "Month",
          "RAD Qgenda Name",
          "SRF Name",
          "Practice",
          "Shift Date",
          "Shift Start Time",
          "Shift End Time",
          "Timezone",
          "Shift Date IST",
          "Shift start Time IST",
          "Shift End Time IST",
          "Year",
          "Last Updated",
          "Is Deleted"
       
        ]}
        data={radScheduleData?.map((e, index) => [
         (offset-1)*20+ index+1,
         e.first_name,
       
         e. last_name,
       
         e.month,
          
         e.rad_qgenda_fullname,
          
         e.rad_srf_name,
         e.practice,
         <div style={{ whiteSpace: "nowrap",
          overflow:" hidden",
          textOverflow: "ellipsis" }}>
         {moment(e.shift_date).format("DD-MMM-YYYY")}
         </div>,
         e.shift_start_time,
         e.shift_end_time,
         e.shift_timezone,
         e.shift_date_ist? moment(e.shift_date_ist).format("DD-MMM-YYYY"): "",
         e.shift_start_time_ist,
         e.shift_end_time_ist,
         e.year,
         moment(e.updated_on).format("DD-MMM-YYYY"),
         e.is_deleted?'true':'false'
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         placeholder="Search by First Name, Last Name"
         limit={20}
        //  placeholder="Search by ID, VRA Name"
      />
    
        </Box>
      </Modal>
    </div>
  )
}

export default RadScheduleForecast