import React, { Fragment, useEffect, useState, useContext } from "react";

import useDebounce from "utils/useDebounce";

import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Pagination from "@mui/material/Pagination";

import NoDataFoundImg from "assets/images/no-data-found2.png";
import Context from "context/context";

const ReusableTable = ({
  title,
  searchBar,
  filter,
  role,
  create,
  create2,
  heading,
  subheading,
  data,
  filterValues,
  setOffset,
  offset,
  count,
  limit,
  setSearchValue,
  filterValue,
  setFilterValue,
  placeholder,
  switchView,
  
}) => {
  const [search, setSearch] = useState(null);
  const searchBounceValues =  useDebounce(search);
  const { state, dispatch } = useContext(Context);
  const effectiveLimit = limit || 10;
  useEffect(() => {
    setSearchValue(searchBounceValues);
    setOffset(1);
  }, [searchBounceValues]);

  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          justifyContent: "end",
          paddingBottom: "15px",
        }}
      >
        <div
          style={{ marginRight: "auto" }}
          // className="EmployeeRoster"
        >
          {title}
        </div>
        {searchBar && (
          <div>
            <TextField
              style={{ border: "1.5px solid #B2D4F8", borderRadius: "5px" }}
              variant="outlined"
              placeholder={placeholder}
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  alignItems: "center",
                  height: "15px",
                  fontSize: "0.8rem",
                  width: "250px",
                },
              }} 
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        {filter && (
          <div>
            <FormControl sx={{ m: 1, minWidth: 120, margin: 0 }}>
              <Select
                displayEmpty
                style={{
                  alignItems: "center",
                  height: "48px",
                  border: "1px solid #8798A9",
                  color: "grey",
                }}
                inputProps={{ "aria-label": "Without label" }}
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.target.value);
                  setOffset(1);
                }}
              >
                {filterValues?.map((f, i) => (
                  <MenuItem value={f.value} key={i}>
                    {f.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {create && <div>{create}</div>}
        {switchView && <div>{switchView}</div>}
      </div>

      {/* Table */}
      {data && data?.length > 0 ? (
        <Fragment>
          {" "}
          <TableContainer
            component={Paper}
            style={{
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            }}
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {heading?.map((h, i) => (
                    <TableCell key={i}>
                      <b className="tablecellforemployeeRoster">{h}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  {subheading?.map((k, i) => (
                    <TableCell key={i}>
                      <b className="tablecellforemployeeRoster">{k}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((arr, i) => (
                  <TableRow key={i}>
                    {arr?.map((str, j) => (
                      <TableCell className="TableCell" key={j}>
                        {str}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Pagination
              style={{ color: "#rgba(0, 112, 233, 1)" }}
              page={offset}
              count={
                (count / effectiveLimit) % 10 == 0
                  ? Math.floor(count / effectiveLimit)
                  : Math.floor((count / effectiveLimit) + 1)
              }
              onChange={(event, value) => {
                setOffset(value);
              }}
            />
          </Box>
       {console.log(effectiveLimit,count,'count')
       } 
        </Fragment>
      ) : (
       !state?.loading && <div style={{ textAlign: "center" }}>
          <img src={NoDataFoundImg} width={350} alt="" />
          <div>No Data Found !</div>
        </div>
      )}
    </div>
  );
};

export default ReusableTable;
